import React, { useState } from 'react';
import './App.css';

function App() {
  const [input, setInput] = useState('');
  const [output, setOutput] = useState('');

  const handleInputChange = (event) => {
    setInput(event.target.value);
  };

  const processInput = () => {
    const lines = input.split('\n');
    const processedLines = lines.map(line => line.trim().replace(/\s+/g, ','));
    setOutput(processedLines.join('\n'));
  };

  return (
    <div className="App">
      <header className="App-header">
        <textarea
          value={input}
          onChange={handleInputChange}
          rows="10"
          cols="50"
          placeholder="Enter your data here..."
        />
        <button onClick={processInput}>Process</button>
        <h2>Processed Output</h2>
        <textarea
          value={output}
          rows="10"
          cols="50"
          readOnly
        />
      </header>
    </div>
  );
}

export default App;
